<template>
  <div class="u-about_zxd">
    <BaseTitle title="关于找向导" desc="about zhao xian gdao"></BaseTitle>
    <figure>
      <img src="/image/about/img_zxd.png" alt="" />
      <figcaption>
        山西找向导科技是一家专门从事计算机软件研发、应用、服务的智能密集型高新技术企业，以大型应用软件开发和计算机系统集成为核心，涵盖
      </figcaption>
    </figure>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.u-about_zxd {
  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    img {
      width: 720px;
      height: 470px;
      display: block;
    }
  }
  figcaption {
    width: 672px;
    height: 240px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #4b4545;
    line-height: 36px;
    padding-left: 15px;
  }
}
</style>
